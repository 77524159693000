import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { getStoreLocatorQuery } from '@ee-monorepo/shared/utilities/functions';
import { MerchantInterface } from '@ee-monorepo/shared/utilities/types';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from './store';

const useStoreLocatorRouterActions = () => {
  const router = useRouter();
  const isNewStoreLocator = !appConfig.enableOldStoreLocator;
  const { push, query } = router || {};
  const dispatch = useDispatch();

  const pushWithPageNumber = useCallback(
    (queryParam: string) => {
      return push(queryParam, undefined, {
        scroll: false,
      });
    },
    [push]
  );

  const pushLocation = useCallback(
    async (zipCode: string) => {
      if (zipCode !== query['zipCode']) {
        dispatch({ type: 'RESET_FILTERS' });
        dispatch({ type: 'RESET_RESULTS' });
        // const { industry } = query;

        pushWithPageNumber(
          `/find-stores?zipCode=${zipCode}${`${
            !isNewStoreLocator ? '&industry=ALL' : ''
          }`}`
        );
      }
    },
    [pushWithPageNumber, dispatch, query, isNewStoreLocator]
  );

  const pushGeoCoordinates = useCallback(
    async (params: {
      latitude: number;
      longitude: number;
      mapInteraction?: boolean;
      resetPageNumber?: boolean;
    }) => {
      if (
        params.latitude !== parseFloat(query['latitude'] as string) ||
        params.longitude !== parseFloat(query['longitude'] as string)
      ) {
        // const { industry } = query;
        dispatch({ type: 'RESET_FILTERS' });
        dispatch({ type: 'RESET_RESULTS' });
        // attach industry if available
        pushWithPageNumber(
          `?latitude=${params.latitude}&longitude=${params.longitude}${`${
            !isNewStoreLocator ? '&industry=ALL' : ''
          }`}${params.mapInteraction ? '&mapInteraction=true' : ''}`
        );
      }
    },
    [pushWithPageNumber, dispatch, query, isNewStoreLocator]
  );

  const pushSearch = useCallback(
    async (search: string) => {
      if (search === query['search']) {
        return;
      }
      dispatch({ type: 'RESET_RESULTS' });
      pushWithPageNumber(
        `${getStoreLocatorQuery(query, { industry: true }, { search })}`
      );
    },
    [pushWithPageNumber, query, dispatch]
  );

  const pushIndustry = useCallback(
    async (industry: string, payload?: { keyword: string }) => {
      if (industry !== query['industry']) {
        dispatch({ type: 'RESET_RESULTS', payload });
        pushWithPageNumber(`${getStoreLocatorQuery(query, {}, { industry })}`);
      }
    },
    [pushWithPageNumber, query, dispatch]
  );

  const pushCityState = useCallback(
    async (state: string, city: string, pageNumber: string) => {
      pushWithPageNumber(
        `${getStoreLocatorQuery(
          query,
          { industry: true, search: true, merchant: true },
          { state, city }
        )}`
      );
    },
    [pushWithPageNumber, query]
  );

  const pushMerchantDetails = useCallback(
    async (merchant: any) => {
      dispatch({ type: 'RESET_FILTERS' });
      push(
        `${getStoreLocatorQuery(
          query,
          { industry: true, cityState: false, search: true, merchant: true },
          {
            zipCode: merchant.zipCode,
            industry: 'ALL',
            pageNumber: '1',
            dbaName: encodeURIComponent(merchant.dbaName.replace(/ /g, '-')),
          }
        )}
        `
      );
    },
    [push, dispatch, query]
  );

  const getInStoreMerchantLink = useCallback(
    (merchant: Partial<MerchantInterface>) => {
      return `${getStoreLocatorQuery(
        query,
        {
          industry: true,
          search: true,
          pageNumber: false,
          mapInteraction: false,
        },
        {
          merchantId: merchant.id?.toString(),
          dbaName: encodeURIComponent(merchant.dbaName.replace(/ /g, '-')),
        }
      )}`;
    },
    [query]
  );

  const getOnlineMerchantLink = useCallback(
    (merchant: MerchantInterface) => {
      return `${getStoreLocatorQuery(
        query,
        {
          industry: true,
          cityState: false,
          search: true,
          pageNumber: true,
        },
        {
          merchantId: merchant.id?.toString(),
          dbaName: encodeURIComponent(merchant.dbaName.replace(/ /g, '-')),
        }
      )}`;
    },
    [query]
  );

  return {
    pushLocation,
    pushGeoCoordinates,
    pushIndustry,
    pushSearch,
    pushCityState,
    pushMerchantDetails,
    getInStoreMerchantLink,
    getOnlineMerchantLink,
  };
};

export { useStoreLocatorRouterActions };
